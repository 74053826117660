import React from 'react'
import { Helmet } from 'react-helmet'
import { Footer } from '../components/Layout/Footer'
import { Container } from '../components/UI/Container'
import { FAQsContent } from '../views/faqs/faqs'
import { Navbar } from '../components/Layout/Navbar'

const FAQsPage = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Frequently Asked Questions — Commercium Bank, Inc</title>
      </Helmet>

      <Navbar />

      <Container tw="pt-32 pb-20">
        <FAQsContent />
      </Container>

      <Footer />
    </div>
  )
}

export default FAQsPage
