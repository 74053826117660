import React from 'react'
import { Title } from '../../components/UI/Title'
import { useState } from 'react';
import { Accordion, AccordionHeader, AccordionBody } from '@material-tailwind/react';
import { Link } from 'gatsby'

function Icon({ id , open }:{id: Number, open: Number}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="rgb(13, 85, 151)"
      strokeWidth={2}
      className={`${
        id === open ? "transform rotate-180" : ""
      } h-5 w-5 transition-transform`}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

export const FAQsContent = () => {
  const [open1, setOpen1] = useState(0);
  const [open12, setOpen12] = useState(0);
  const [open13, setOpen13] = useState(0);
  const [open2, setOpen2] = useState(0);
  const [open3, setOpen3] = useState(0);
  const [open4, setOpen4] = useState(0);
  const [open5, setOpen5] = useState(0);
  const [open6, setOpen6] = useState(0);
  const [open7, setOpen7] = useState(0);
  const [open18, setOpen18] = useState(0);
  const [open8, setOpen8] = useState(0);
  const [open9, setOpen9] = useState(0);
  const [open10, setOpen10] = useState(0);
  const [open19, setOpen19] = useState(0);
  const [open11, setOpen11] = useState(0);
  const [open17, setOpen17] = useState(0);
  const [open20, setOpen20] = useState(0);
  const [open21, setOpen21] = useState(0);
  const [open22, setOpen22] = useState(0);
  const [open23, setOpen23] = useState(0);
  const [open24, setOpen24] = useState(0);
  const [open25, setOpen25] = useState(0);
  const [open26, setOpen26] = useState(0);
  const [open27, setOpen27] = useState(0);
  const [open28, setOpen28] = useState(0);
  const [open29, setOpen29] = useState(0);
  const [open14, setOpen14] = useState(0);
  const [open15, setOpen15] = useState(0);
  const [open16, setOpen16] = useState(0);
 
  const handleOpen1 = (value: React.SetStateAction<number>) => { setOpen1(open1 === value ? 0 : value); };
  const handleOpen12 = (value: React.SetStateAction<number>) => { setOpen12(open12 === value ? 0 : value); };
  const handleOpen13 = (value: React.SetStateAction<number>) => { setOpen13(open13 === value ? 0 : value); };
  const handleOpen2 = (value: React.SetStateAction<number>) => { setOpen2(open2 === value ? 0 : value); };
  const handleOpen3 = (value: React.SetStateAction<number>) => { setOpen3(open3 === value ? 0 : value); };
  const handleOpen4 = (value: React.SetStateAction<number>) => { setOpen4(open4 === value ? 0 : value); };
  const handleOpen5 = (value: React.SetStateAction<number>) => { setOpen5(open5 === value ? 0 : value); };
  const handleOpen6 = (value: React.SetStateAction<number>) => { setOpen6(open6 === value ? 0 : value); };
  const handleOpen7 = (value: React.SetStateAction<number>) => { setOpen7(open7 === value ? 0 : value); };
  const handleOpen18 = (value: React.SetStateAction<number>) => { setOpen18(open18 === value ? 0 : value); };
  const handleOpen8 = (value: React.SetStateAction<number>) => { setOpen8(open8 === value ? 0 : value); };
  const handleOpen9 = (value: React.SetStateAction<number>) => { setOpen9(open9 === value ? 0 : value); };
  const handleOpen10 = (value: React.SetStateAction<number>) => { setOpen10(open10 === value ? 0 : value); };
  const handleOpen19 = (value: React.SetStateAction<number>) => { setOpen19(open19 === value ? 0 : value); };
  const handleOpen11 = (value: React.SetStateAction<number>) => { setOpen11(open11 === value ? 0 : value); };
  const handleOpen17 = (value: React.SetStateAction<number>) => { setOpen17(open17 === value ? 0 : value); };
  const handleOpen20 = (value: React.SetStateAction<number>) => { setOpen20(open20 === value ? 0 : value); };
  const handleOpen21 = (value: React.SetStateAction<number>) => { setOpen21(open21 === value ? 0 : value); };
  const handleOpen22 = (value: React.SetStateAction<number>) => { setOpen22(open22 === value ? 0 : value); };
  const handleOpen23 = (value: React.SetStateAction<number>) => { setOpen23(open23 === value ? 0 : value); };
  const handleOpen24 = (value: React.SetStateAction<number>) => { setOpen24(open24 === value ? 0 : value); };
  const handleOpen25 = (value: React.SetStateAction<number>) => { setOpen25(open25 === value ? 0 : value); };
  const handleOpen26 = (value: React.SetStateAction<number>) => { setOpen26(open26 === value ? 0 : value); };
  const handleOpen27 = (value: React.SetStateAction<number>) => { setOpen27(open27 === value ? 0 : value); };
  const handleOpen28 = (value: React.SetStateAction<number>) => { setOpen28(open28 === value ? 0 : value); };
  const handleOpen29 = (value: React.SetStateAction<number>) => { setOpen29(open29 === value ? 0 : value); };
  const handleOpen14 = (value: React.SetStateAction<number>) => { setOpen14(open14 === value ? 0 : value); };
  const handleOpen15 = (value: React.SetStateAction<number>) => { setOpen15(open15 === value ? 0 : value); };
  const handleOpen16 = (value: React.SetStateAction<number>) => { setOpen16(open16 === value ? 0 : value); };

  return (
    <div>
      <Title tw="text-center">Frequently Asked Questions</Title>

      <div tw="mt-10 space-y-6">
      
        <h5 tw="text-commerciumDarkBlue text-2xl font-bold">Background Information on Commercium and SPDI's</h5>


        <Accordion open={open1 === 1} icon={<Icon id={1} open={open1} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen1(1)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>Is Commercium a bank?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>Yes - Commercium is a state-chartered bank under Wyoming's special purpose depository 
              institution ("SPDI") law. As a depository institution, Commercium meets the definition of a bank 
              under state and federal law.</p>
          </AccordionBody>
        </Accordion>

        <Accordion open={open12 === 12} icon={<Icon id={12} open={open12} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen12(12)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>Is Commercium a "crypto bank"?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>The SPDI Charter allows Commercium to hold and take custody of public network-based 
              cryptocurrencies. However, given the uncertain regulatory environment for crypto currencies, 
              Commercium will not enter this market until regulatory certainty has been achieved. In essence, 
              we do not see us entering this space until at least one of the four Large US Banks from JP Morgan, 
              BNY Mellon, Citibank or State Bank have established this capability. Additionally, before launch 
              we would seek approval from both the Wyoming Division of Banking and the Federal Reserve Board of 
              Kansas City.</p>
          </AccordionBody>
        </Accordion>

        <Accordion open={open13 === 13} icon={<Icon id={13} open={open13} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen13(13)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>Are SPDIs banks?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>SPDIs are sometimes described as "bank-like" or "bank-lite". Not the case. SPDIs are banks 
              under state and federal law. The SPDI charter is a bank charter - specifically, a depository 
              institution, which is a distinction that unlocks certain regulatory benefits and obligations.</p>
          </AccordionBody>
        </Accordion>

        <Accordion open={open2 === 2} icon={<Icon id={2} open={open2} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen2(2)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>How is a Special Purpose Depository Institution (SPDI) Compared/different to a Trust Company (TC)?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>A Wyoming-chartered SPDI is a fully reserved bank that receives deposits and conducts other 
              activity related to the business of banking, including custody, asset servicing, fiduciary asset 
              management, and related activities. SPDIs can handle digital assets, such as virtual currencies, 
              digital securities, and digital consumer assets. SPDIs may deal in traditional assets as well, by 
              serving as a vehicle for business cash management, operational accounts, and any other purpose 
              permitted under applicable law.</p><br />

            <p>A TC is not a bank, rather is a separate corporate entity owned by a bank or other financial 
              institution, law firm, or independent partnership. Its function is to manage trusts, trust funds, 
              and estates for individuals, businesses, and other entities. A trust is an arrangement that 
              allows a third party or trustee to hold assets or property for a beneficiary or beneficiaries. 
              TCs get their title from the fact that they act in a fiduciary capacity for their clients—as 
              trustees.</p><br />

            <p><u>Differences</u></p><br />

            <p>SPDIs and TCs are different in multiple ways. Important differences include:</p>
              <ol tw="list-decimal list-outside ml-14">
                <li>SPDIs are regulated as banks and must comply with banking regulations and special, strict 
                  regulations applicable to SPDIs (for example, have their customer deposits of fiat currency at 
                  all times backed 100% or more by unencumbered liquid assets, which include U.S. currency and 
                  level 1 high-quality liquid assets).</li>
                <li>SPDIs advance qualified custody of securities and digital assets. SPDIs may provide custodial 
                  services for digital assets and perform authorized transactions for customers. Further, the 
                  Wyoming SPDI laws mean that only SPDIs can provide an effective bailment while permitting direct 
                  ownership of securities, all provable through the blockchain.</li>
                <li>SPDIs may conduct activity under Wyoming regulations tailored to digital assets, which address 
                  issues such as technology controls, transaction handling, and custody operations for digital 
                  assets.</li>
                <li>SPDIs operate under Wyoming law that defines digital assets in conjunction with the Wyoming 
                  Uniform Commercial Code and describes, among things, perfection and priority of security 
                  interests in digital assets.</li>
                <li>SPDIs may resemble custody banks because they will likely be predominantly engaged in custody, 
                  safekeeping, and asset servicing activities. A custody bank is focused on safekeeping assets, 
                  fiduciary management, transaction processing and settlement, and providing an "on/off" ramp to 
                  securities markets, commodities markets, and customer bank accounts.</li>
                <li>A SPDI can engage in a wider range of activities than a TC.</li>
                <li>A TC is often owned by another entity, such as a bank.</li>
              </ol>
          </AccordionBody>
        </Accordion>

        <Accordion open={open3 === 3} icon={<Icon id={3} open={open3} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen3(3)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>Are deposits at Commercium FDIC-insured?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>No, deposits with Commercium are not FDIC-insured. However, Commercium must comply with all SPDI 
            reserve requirements,such as holding 100% of deposits in reserve. These requirements ensure the safety 
            of customer deposits.</p>
          </AccordionBody>
        </Accordion>

        <Accordion open={open6 === 6} icon={<Icon id={6} open={open6} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen6(6)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>What is Fractional Reserve Banking?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>Fractional Reserve Banking is a system where only a fraction of their depositors' 
              balances are available for withdrawal, with the rest used to lend out to other customers. For 
              example: if a customer deposits $100 in their bank with a reserve requirement of 10%, $10 is 
              left in reserve while the remaining $90 is lent out to another customer.</p><br />
              
            <p>If depositors simultaneously lose confidence, especially if the bank's investments 
              underperform or lose money, and try to withdraw their funds, the bank runs the possibility 
              of not having enough liquid cash on hand to cover all withdrawals, leading to a bank run.</p>
          </AccordionBody>
        </Accordion>

        <Accordion open={open5 === 5} icon={<Icon id={5} open={open5} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen5(5)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>Why Commercium DOES NOT present additional risks than a domestic bank.</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <ol tw="list-decimal list-outside ml-14">
              <li>Our Banking services are traditional banking products:</li>
                <ul tw="list-disc list-outside ml-14">
                  <li>USD checking, savings, escrow accounts</li>
                  <li>Payment services (ACH, wire, Bill Pay)</li>
                </ul>
              <li>Full Reserve Bank versus Fractional Reserve Banking:</li>
                <ul tw="list-disc list-outside ml-14">
                  <li>100% of Commercium customer deposits are held in reserves.</li>
                  <li>$30 million capital</li>
                  <li>Our Charter prohibits us from engaging in any form of lending.</li>
                  <li>Our Charter prohibits the issuance of credit cards.</li>
                </ul>
              <li>We currently have no plans to enter the crypto currency space (see earlier question)</li>
              <li>Stablecoins - given the four Large US Banks are already operating in this space, Commercium 
                plan to also enter this market. We will only utilize those tokens that have 100% USD backing 
                of cash and US securities such as USDC from Circle, and the to be shortly launched Wyoming 
                Stable Token
              </li>
              <li>Our tokenization aspirations are already in place by major banks in the US such JPMC (its 
                partnerships with ONYX) and BNY Mellon.
              </li>
            </ol>
          </AccordionBody>
        </Accordion>

        <Accordion open={open7 === 7} icon={<Icon id={7} open={open7} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen7(7)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>Do all States require insurance or to be federally regulated?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>Historically, the choice whether states would require their banks to be insured or federally 
              regulated rested solely with the states, not with the Federal Government, and until approximately 
              1990 most state laws were silent on the topic. Banks simply chose to be FDIC insured and federally 
              regulated, and the Fed and FDIC routinely granted them access. But five U.S. states held back, 
              keeping flexibility for themselves and a check on potential overreach by federal bank regulators. 
              The five states - Connecticut, Maine, Nebraska, Vermont and Wyoming - enacted bank charters that 
              neither require insurance nor federal regulation. Such uninsured state banks are prohibited from 
              lending (either explicitly by law or functionally), and therefore hold 100% cash to back customer 
              deposits plus up to 8% of deposits as an additional capital requirement.</p>
          </AccordionBody>
        </Accordion>

        <Accordion open={open4 === 4} icon={<Icon id={4} open={open4} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen4(4)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>How Are Customer Deposits Protected Without FDIC Insurance?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>Commercium, unlike many banks, does not engage in lending or fractional reserve banking, 
              instead, 100% of customer deposits are held in reserve with additional protective measures. 
              Here is how deposits are safeguarded in the event of bankruptcy:</p>
            <ul tw="list-disc list-outside ml-14">
              <li><b>100% Reserve in Liquid Assets:</b> All customer deposits are held in unencumbered, high-quality 
                liquid assets. Specifically, Commercium's investment policy mandates retaining at least 20% in 
                cash. The remainder is invested in US Treasury bills with a maximum tenure of 3 months. This, 
                combined with constant liquidity management ensures that all customers will be able to access their 
                deposits immediately. In the unlikely event of a very severe run on the bank occurs, 100% of all 
                deposited funds will be available within 3 months. However due to the nature of 100% reserves, the 
                chances of a bank run are much less likely than at a fractional reserve bank.</li>
              <li><b>Capital Reserves:</b> Beyond holding 100% of customer deposits, Commercium will 
                maintain a minimum capital reserve of $30 million.</li>
              <li><b>Legal Framework & Guidelines:</b> Although the Wyoming Banking Division hasn't yet overseen a 
                liquidation, their procedures are outlined in section 12-12-123 of the SPDI Charter under the 
                voluntary dissolution section.</li>
                <ul tw="list-disc list-outside ml-14">
                  <li>
                    <Link to="https://wyoleg.gov/statutes/compress/title13.pdf" target="_blank" tw="underline hover:text-blue-800">
                      Title 13 - Banks, Banking and Finance
                    </Link>
                  </li>
                </ul>
              <li><b>Capital Requirements:</b> Prior to opening, Commercium is required to retain capital of at 
                least  $30 million. As the bank grows its assets, the minimum capital requirement will be either 
                $30 million or 2% of assets, whichever is greater.</li>
                
              <li><b>Asset Pledge:</b> $15 million of bank assets will be legally pledged to the 
                State Banking Division, ensuring they can promptly address potential harm to the SPDI charter 
                during any forced or voluntary liquidation. This pledged amount is accessible immediately, even 
                before any remaining capital post-liquidation.</li>
            </ul>
          </AccordionBody>
        </Accordion>

      <h5 tw="text-commerciumDarkBlue text-2xl font-bold">Commercium's Business Plan</h5>

        <Accordion open={open18 === 18} icon={<Icon id={18} open={open18} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen18(18)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>What are Commercium's business plans and objectives?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>Wyoming Banking Division has led the nation in creating the regulatory framework that 
              established the Special Purpose Depository Institutions (SPDI) regime, being a non-lending 
              banking license for digital asset connectivity to traditional banking systems. We are a full 
              reserve bank as we will hold 100% of customer deposits in reserves (unencumbered liquid assets). 
              Commercium is one of four SPDIs that have received Charter approval, where we aim to take 
              advantage of digital banking and qualified custody of digital assets and digital securities, 
              under Wyoming's customer friendly bailment legislation.</p><br />

            <p>We will be fully compliant in all aspects of Bank Secrecy Act/AML and sanctions regulations. 
              Digitally organized at the outset, we aim to be lean and agile, retain a low-cost base and 
              offer new to the market digital products using the blockchain technology platform.</p><br />

            <p>By becoming a chartered bank, Commercium will legally be able to offer a variety of new 
              services; each of which will help the company achieve its end goal of bridging traditional 
              wealth management with the world of blockchain and digital custody. These offering will 
              include:</p><br />
            <ul tw="list-disc list-outside ml-14">
              <li>Custody of digital assets and digital securities. As a Bank we will adhere to the 
                standards of traditional Trust and Custodial institutions.</li>
              <li>Tokenization of financial and real-world assets for Commercium Corporate clients and 
                HNWI's. We will partner with a tokenization service company</li>
              <li>Custody of 100% US$ backed stablecoins</li>
              <li>FIAT checking, savings, escrow accounts and payment services (ACH, wire, Bill Pay).</li>
            </ul><br />
            
            <p>Commercium Bank will be able to take custody of both fiat and crypto assets, with more 
              oversight and legal protection for clients than a trust company can offer. Client confidence 
              will get a further boost through the additional capital that SPDI banks are required to 
              hold (100% of unencumbered liquid assets). Although chartered in Wyoming, Commercium Bank 
              will be able to operate in most U.S. states under a unified regulatory framework through 
              reciprocity agreements, which is good for Commercium, but also for the industry as a whole, 
              as it will facilitate onboarding for a range of businesses and institutions that are only 
              comfortable entrusting financial transactions to a bank.</p>
          </AccordionBody>
        </Accordion>
        
        <Accordion open={open8 === 8} icon={<Icon id={8} open={open8} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen8(8)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>Can you take both commercial and retail accounts and deposits?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>As part of Commercium's core banking implementation, Commercium will test and have 
              approval to launch products and services for commercial and retail customers. 
              Commercium's plan for day 1 is commercial, but by testing retail products this means 
              Commercium can launch at a much quicker pace later on.</p>
          </AccordionBody>
        </Accordion>

        <Accordion open={open9 === 9} icon={<Icon id={9} open={open9} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen9(9)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>Can you take accounts from non-US citizens or offer products to non-US citizens?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>The Wyoming Division of Banking has expressed no objections to allowing SPDI's to accept non-US 
              citizen customers or deposits. The Wyoming Division of Banking has, however, indicated this practice 
              may affect the risk profile of the SPDI and could in turn require modified AML/BSA diligence 
              requirements for onboarding non-US customers, as well as additional requirements for compliance 
              on examination, but it can be permitted.</p>
          </AccordionBody>
        </Accordion>

        <Accordion open={open10 === 10} icon={<Icon id={10} open={open10} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen10(10)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>What is the "customer service" plan for Commercium?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>As part of our core banking implementation (checking accounts, savings accounts, bank services) 
              with NYMBUS, we have secured alongside a back-office capability and a call center capability. 
              These two areas from NYMBUS who already serve many banks, will be linked with the proposed 
              Commercium back-office capabilities.</p>
          </AccordionBody>
        </Accordion>

        <Accordion open={open19 === 19} icon={<Icon id={19} open={open19} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen19(19)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>Your website states that the bank will be able to operate in most of the US through 
              reciprocity agreements. Are these just Money Transmitter License (MTL) reciprocity agreements 
              or are there others?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>The Wyoming Division of Banking permits SPDI's to conduct business and operate throughout 
              the US. In addition, most states have reciprocity arrangements to qualified banks that operate 
              in those states as a money transmitter. However, a certain number of states have been 
              identified that require separate MTL licensure for SPDI's. The list below, which may be 
              subject to change and is under ongoing evaluation and discussion by the Wyoming Division of 
              Banking, identifies those states which require separate MTL's:</p>
            <ul tw="list-disc list-outside ml-14">
              <li>Alabama</li>
              <li>Alaska</li>
              <li>Arkansas</li>
              <li>California</li>
              <li>Connecticut</li>
              <li>Georgia</li>
              <li>Iowa</li>
              <li>Louisiana</li>
              <li>Maine</li>
              <li>Michigan</li>
              <li>Mississippi</li>
              <li>New Mexico</li>
              <li>Oregon</li>
              <li>Pennsylvania</li>
              <li>South Carolina</li>
              <li>Texas</li>
              <li>West Virginia</li>
            </ul><br />

            <p>Those states not listed above have expressed or indicated reciprocity for purposes of not 
              requiring separate MTL's for operation.</p><br />

            <p>We will work with the Wyoming Division of Banking with all those US States that require an 
              individual MTL license approach.</p><br />

            <p>Lastly, please keep in mind this list does not include New York, as the BitLicense process 
              has been handled and approached separately and has separate requirements.</p>
          </AccordionBody>
        </Accordion>

      <h5 tw="text-commerciumDarkBlue text-2xl font-bold">Regulatory and Compliance</h5>

        <Accordion open={open11 === 11} icon={<Icon id={11} open={open11} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen11(11)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>How is Commercium regulated?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>Commercium is a state-chartered U.S. bank, regulated by the Wyoming Division of Banking, and 
              is subject to the same standards that govern all banks, including bank-level capital requirements 
              and bank-level compliance requirements (including the higher standard that applies to banks 
              called the "Customer Due Diligence Rule"). Additionally, as a regulated bank, Commercium will 
              be subject to frequent supervisory examinations that may not apply to non-banks.</p>
          </AccordionBody>
        </Accordion>

        <Accordion open={open17 === 17} icon={<Icon id={17} open={open17} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen17(17)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>Describe the compliance program established or will be established to oversee the program?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>As a compliance-led organization, our compliance department has actively worked to build out 
              our internal controls, procedures, and policies designed to identify, monitor, and mitigate 
              risks associated with the bank's operations and ensure Commercium can meet all regulatory 
              objectives. The compliance department is currently overseen by our Chief Compliance/BSA Officer 
              (CCO), Corey Reason. Our CCO oversees all compliance and risk matters and leads the department. 
              Our Local Compliance Officer (LCO) reports to the CCO and works closely with other departments 
              to address compliance questions before elevating appropriate concerns to the CCO.</p><br />
            
            <p>As a compliance-led organization, our compliance department has actively worked to build out 
              our internal controls, procedures, and policies designed to identify, monitor, and mitigate 
              risks associated with the bank’s operations and ensure Commercium can meet all regulatory 
              objectives. The compliance department is currently overseen by our Chief Compliance/BSA Officer 
              (CCO), Corey Reason. Our CCO oversees all compliance and risk matters and leads the department. 
              Our Local Compliance Officer (LCO) reports to the CCO and works closely with other departments 
              to address compliance questions before elevating appropriate concerns to the CCO.</p><br />
            
            <p>As we get closer to opening, the department has plans to scale. This expansion will include a 
              team of enterprise risk managers, fraud risk managers, business analysts, transaction monitoring 
              analyst, and KYC analysts for transaction monitoring, evaluating potential risks, and 
              implementing appropriate controls and monitoring procedures to mitigate those risks.</p>
          </AccordionBody>
        </Accordion>

        <Accordion open={open20 === 20} icon={<Icon id={20} open={open20} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen20(20)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>What are your reporting obligations for transaction monitoring and liquidity reserves?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>Liquidity - Commercium (Finance) will need to deliver a Call Report quarterly once open. In 
              addition, Commercium will need to supply a detailed cash sources and uses report quarterly. 
              Transaction Monitoring - Fiat transactions via Commercium's core banking platform is monitored 
              by Abrigo (used in 2400 financial institutions) and USDC/digital transactions by 
              Chainalysis (market leader in digital transaction monitoring)</p>
          </AccordionBody>
        </Accordion>

      <h5 tw="text-commerciumDarkBlue text-2xl font-bold">Commercium's Needs to Open</h5>

        <Accordion open={open21 === 21} icon={<Icon id={21} open={open21} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen21(21)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>What is the "Certificate of Authority" required and will you be able to obtain this? </h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>Commercium has been given a Certificate of Authority (COA) Requirements letter by the Wyoming 
              Division of Banking that lists 38 items which will need to be completed before Commercium will 
              receive a Certificate of Authority. Of the 38 items required to receive the COA, Commercium 
              believe that 30 of these items are completed and ready for certification. There are 3 items in 
              progress (finalize policies - 90%+ completed) and  5 remaining items awaiting raise of 
              capital and produce a policy list.</p>
          </AccordionBody>
        </Accordion>
        
        <Accordion open={open22 === 22} icon={<Icon id={22} open={open22} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen22(22)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>What services is Commercium looking for from a banking partner?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>USD checking, savings, FBO accounts, escrow accounts, and payment services (ACH, wire, Bill Pay).</p>
          </AccordionBody>
        </Accordion>
        
        <Accordion open={open23 === 23} icon={<Icon id={23} open={open23} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen23(23)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>Can you provide details on  Commercium Legal Entitlement & Routing #</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>On the 2nd August 2022, Commercium received confirmation from the Federal Reserve Bank of 
              Kansas City (FRBKC) that we satisfy the threshold legal definition of an entity eligible to 
              maintain a master account.</p><br />
            
            <p>On the 7thf September 2022, Commercium received its routing number but was denied 
              permission to attach settlements, until such time we received FRBKC approval (see appendix 
              B for copy of this confirmation).</p><br />
            
            <p>On the 7thf September 2022, Commercium received its routing number but was denied 
              permission to attach settlements, until such time we received FRBKC approval (see 
              appendix B for copy of this confirmation).</p>
          </AccordionBody>
        </Accordion>
        
        <Accordion open={open24 === 24} icon={<Icon id={24} open={open24} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen24(24)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>Can you provide high-level categories of the type of software you will need to purchase to stand up the bank?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>Commercium has a core banking relationship with Nymbus (approved by the Wyoming Division 
              of Banking) and their partners such as Socure (identification & verification for retail), 
              MidDesk (identification and verification for Commercial), Abrigo (Compliance), and 
              Flexi (general ledger). As part of the Nymbus package we have secured back-office 
              support, call center support, and liaison with Commercium back-office staff via workflow 
              system.</p><br />
            
            <p>Commercium's digital infrastructure includes an account with Circle for stablecoin; level 1
              wallet management infrastructure using Fireblocks, and transaction monitoring using 
              Chainalysis.</p>
          </AccordionBody>
        </Accordion>

      <h5 tw="text-commerciumDarkBlue text-2xl font-bold">Funding and Acquisition</h5>

        <Accordion open={open25 === 25} icon={<Icon id={25} open={open25} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen25(25)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>What is the capital required to open the Bank?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>The letter states Commercium must have a minimum of $30 million in capital to open the bank. 
              Further, the letter states the Commercium Board must adopt a resolution stating the bank cannot 
              go below $30 million in capital without the prior written consent of the Wyoming Division 
              of Banking.</p>
          </AccordionBody>
        </Accordion>

        <Accordion open={open26 === 26} icon={<Icon id={26} open={open26} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen26(26)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>Please explain the breakdown of round 2 funding needed.</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>$30 Minimum Capital level required<br />
            <b>Use of Cash</b></p><br />

            <ul tw="list-disc list-outside ml-14">
              <li>$15M: Asset Pledge (restricted cash) to be invested in unencumbered liquid assets</li>
              <li>$15M: Unused, unrestricted cash to be invested in unencumbered liquid assets</li>
              <li>$15M: Operating Expenses for first year of live operations, and other potential reserves</li>
            </ul><br />
            
            <p>The $45 million gives Commercium sufficient capital to open and enough 
              runway, we believe, to get to profitability. We can meet the requirement to pledge 
              $15 million in assets from the cash we receive from the capital infusion.</p>
          </AccordionBody>
        </Accordion>

        <Accordion open={open27 === 27} icon={<Icon id={27} open={open27} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen27(27)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>How did you arrive at the $45MM capital outlay estimate for these licenses?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>The capital outlay is not solely for licenses, but is to meet the statutory 
              requirements, and 3-year operating expenses. Commercium currently has 8 staff in-situ, 
              6 executives, 3 part-time executives, and 4 other Directors on the Board. 
              Capital will be used for increased staff to commence the implementation activities 
              and setting up the SPDI operations.</p><br />

            <p>Corporate relationships and terms are nearing conclusion, and the plan for integration 
              is in place with Nymbus and has been approved by Wyoming Division of Banking. Our 
              chosen custody provider has also been provisionally agreed by the Wyoming Division 
              of Banking.</p>
          </AccordionBody>
        </Accordion>

        <Accordion open={open28 === 28} icon={<Icon id={28} open={open28} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen28(28)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>Under Wyoming law, what type of change in control procedures and reporting are required to approve any future acquisition?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>There are many dependencies that affect the timeline, and ultimately approval of any 
              change in control is up to the Wyoming Division of Banking to approve. The key to speedy 
              approval is full identification and verification of all personnel involved and timely 
              responses to their requests.</p>
          </AccordionBody>
        </Accordion>

        <Accordion open={open29 === 29} icon={<Icon id={29} open={open29} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen29(29)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>Describe the terms of structuring the ownership interest of a principal shareholder and controlling shareholder.</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>Under the Wyoming Division of Banking's regulations there is a requirement to disclose 
              certain information regarding parties that will have a proposed controlling interest in 
              the entity. A controlling interest is defined as a person or cohort of persons who:</p>
            <ul tw="list-disc list-outside ml-14">
              <li>Owns, controls, or has the power to vote 25 percent or more of any class of voting 
                securities of the institution.</li>
              <li>Controls in any manner the election of a majority of the directors of the institution.</li>
              <li>Have the power to exercise a controlling influence over the management or policies of 
                the institution.</li>
            </ul><br />

            <p>Any person with a controlling interest in an SPDI must submit audited financial statements, 
              at least annually, a description of affiliated or parent entities, and serve as a source of 
              strength for the institution. Serving as a source of strength may include providing capital 
              plans, maintenance agreements or agreements for resource-sharing.</p><br />
            
            <p>If a legal entity has a controlling interest, the Wyoming Division of Banking Commissioner 
              may require the entity to execute a tax allocation agreement with the SPDI, specifying 
              that an agency relationship exists with respect to tax assets generated by the SPDI. 
              Such assets are "held in trust for the benefit of the institution and will be promptly 
              remitted to the institution". The Commissioner is empowered to conduct an examination of 
              a person with a controlling interest or otherwise require information of that person. 
              The Commissioner may also require a person with a controlling interest to divest or sever 
              their relationship with the SPDI. This regulation also prohibits a "commercial firm" from 
              obtaining a controlling interest in an SPDI. "[A] company is a 'commercial firm' if the 
              annual gross revenues derived by the company and all of its affiliates from activities 
              that are financial in nature . . . and, if applicable, from the ownership or control of 
              one or more insured depository institutions, represent less than 15 percent of the 
              consolidated annual gross revenues of the company."</p><br />
            
            <p>The Wyoming Division of Banking requires SPDI applicants to file a Biographical & 
              Financial Report with the charter application and for a change in control. The report 
              requires an individual who is a "principal shareholder", of ten percent or more of the 
              voting securities, to recognize such a position and provide the information required 
              by the report. Such information includes whether the filer has been involved in a 
              charter or license application that was unsuccessful in which the filer was a Principal 
              Shareholder. It further asks whether the filer has been involved in a "notice of change 
              in control for a depository institution" that was unsuccessful in which the filer was an 
              acquirer or transferee.</p><br />
            
            <p>There are other aspects of the change in control for purposes of Wyoming Division of 
              Banking approval, however, the above should provide a general overview of the type of 
              information which will be required.</p>
          </AccordionBody>
        </Accordion>

      <h5 tw="text-commerciumDarkBlue text-2xl font-bold">Blockchain and Tokenization</h5>

        <Accordion open={open14 === 14} icon={<Icon id={14} open={open14} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen14(14)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>Since Commercium uses blockchain technology, how is network security managed and tested?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>We will leverage vendor-provided blockchain technology. As with any software vendor, the security 
              measures will be proven through their documentation and will first demonstrate software compliance 
              through initial testing before being accepted and integrated. (For reference, it's the same process 
              we would use to bring on any software from Nymbus to Microsoft Word.) User acceptance testing, 
              penetration testing, and deployment testing procedures, etc. will be developed for system approval 
              through both internal and third-party evaluations before we are certified by the Wyoming Division 
              of Banking.</p>
          </AccordionBody>
        </Accordion>

        <Accordion open={open15 === 15} icon={<Icon id={15} open={open15} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen15(15)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>How is the bank set up to support a USA digital currency?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>Part of our software vendor selection will include the ability to support the integration of 
              multiple blockchain types. This is designed to ensure we can adapt to any regulatory-approved 
              developments. While no one can yet guarantee support of any future US-backed digital currency, 
              we are following the development of the Central Bank Digital Currency (CBDC) to ensure we have 
              the maximum opportunity to complement these developments.</p>
          </AccordionBody>
        </Accordion>

        <Accordion open={open16 === 16} icon={<Icon id={16} open={open16} />} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="!mt-3">
          <AccordionHeader onClick={() => handleOpen16(16)} nonce={undefined} onResize={undefined} onResizeCapture={undefined} tw="bg-blueGray-100 shadow-sm px-6 py-2 rounded-t-md text-lg font-normal">
            <h6>What is Rule 15c3-3 Qualified Custody of Securities, and why is it Important?</h6>
          </AccordionHeader>
          <AccordionBody tw="bg-blueGray-50 shadow-sm p-6 rounded-b-md text-base">
            <p>The Securities Exchange Act Rule 15c3-3, commonly referred to as the Customer Protection Rule, 
              is a critical regulation that aims to safeguard the funds and securities of customers held by 
              broker-dealers. This rule imposes specific requirements on firms to ensure the protection of 
              customer assets and the proper segregation of those assets from the broker-dealer's own funds.</p><br />

            <p>The Securities and Exchange Commission (SEC) established Rule 15c3-3 in 1972 in response to 
              a congressional directive to create rules concerning the acceptance, custody, and use of 
              customer securities. Under this rule, broker-dealers are required to take steps to protect 
              the securities that customers leave in their custody.</p><br />

            <p>Rule 15c3-3 ensures that brokerage firms maintain secure accounts so that clients can withdraw 
              their assets at any time. These measures include the segregation of customer assets and proper 
              record-keeping to help prevent the misuse of customer funds or securities.</p><br />

            <p><b>Qualified custody is important for several reasons:</b></p><br />

            <p>Customer protection: The primary goal of Rule 15c3-3 is to safeguard customer funds and 
              securities. By ensuring proper segregation of customer assets and strict adherence to 
              regulatory requirements, customers can have peace of mind that their investments are protected 
              against potential broker-dealer insolvencies, fraud, or mismanagement.</p><br />
            
            <p>Ensuring financial stability: Qualified custody helps maintain the financial stability of the 
              securities market by reducing the risk of broker-dealer failures that could have widespread 
              consequences. By protecting customer assets, this rule promotes confidence in the financial 
              system and encourages continued investment and participation in the securities market.</p><br />
            
            <p>Regulatory compliance: Broker-dealers that follow Rule 15c3-3 are better equipped to meet 
              their regulatory obligations, which in turn helps to build trust among customers, regulators, 
              and other stakeholders. Compliance with this rule demonstrates the broker-dealer's commitment 
              to protecting customer assets and maintaining high standards of conduct.</p><br />
            
            <p><b>Role of Qualified Custodians in Secondary Market Trading of Securities</b></p><br />

            <p>Qualified custodians play a crucial role in the secondary market trading of securities by 
              ensuring that customer assets are managed and held securely. A qualified custodian is a 
              financial institution, such as a bank or trust company, that is authorized to hold and 
              safeguard customer funds and securities according to the regulatory requirements outlined 
              in Rule 15c3-3.</p><br />
            
            <p>In secondary market trading, investors buy and sell securities that have already been 
              issued, such as stocks, bonds, and other financial instruments. Qualified custodians ensure 
              that these transactions are conducted securely, transparently, and in accordance with 
              applicable regulations. By doing so, they help to maintain investor confidence in the 
              market and facilitate the smooth operation of secondary market trading.</p><br />
            
            <p>Additionally, qualified custodians play a vital role in the ongoing innovation and 
              integration of new financial products into the securities market. For example, the SEC 
              has issued a statement and request for comment regarding the custody of digital asset 
              securities by broker-dealers, encouraging innovation around the application of Rule 15c3-3 
              to digital asset securities.</p><br />
            
            <p>Additionally, qualified custodians play a vital role in the ongoing innovation and 
              integration of new financial products into the securities market. For example, the SEC has 
              issued a statement and request for comment regarding the custody of digital asset securities 
              by broker-dealers, encouraging innovation around the application of Rule 15c3-3 to digital 
              asset securities.</p>
          </AccordionBody>
        </Accordion>

      </div>

    </div>
  )
}